import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'

const CabaretInfosReglements = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>

        <CabaretInfosNavigation />

        <h1>Règlements</h1>

        <div className='cabaret-infos-inner'>

            <h2>Inscriptions et processus de sélection</h2>
            <h3>Les projets musicaux intéressés à participer au concours sont invités à soumettre leur candidature en envoyant un dossier correspondant aux exigences du formulaire et à la liste de règlements du concours et ce, à l’intérieur de la période d’inscription annoncée pour l’édition à venir.</h3>
            <h3>À la fermeture de la période d’inscriptions, l’équipe du Festif! prend plusieurs journées pour évaluer chacune des candidatures reçues et vérifier l’éligibilité des projets.</h3>
            <h3>Les dossiers sont finalement envoyés pour analyse finale à un comité de sélection constitué d’une quinzaine de professionnel.les de l’industrie musicale québécoise (diffuseurs, médias, artistes, producteurs et productrices, gérant.es, etc), qui déterminent ensuite, à l’aide d’un système de pointage, la cohorte des 12 projets participants à la prochaine édition du concours.</h3>

            <h2>Spectacles</h2>
            <h3>Le Cabaret Festif! se déroule sur quatre soirées de spectacles; trois soirées de qualifications ainsi que la Grande Finale du concours.</h3>
            <h3>Lors de chacune des trois soirées de qualifications, quatre projets sont en prestation dans le but, en plus de se faire découvrir et de rencontrer un public nouveau, d’obtenir une place à la finale. À l’issue de chaque soirée, un projet est choisi par le jury et obtient automatiquement une place à la finale. Le public présent choisit aussi son projet coup de cœur parmi les quatre prestations. Les deux projets non sélectionnés voient alors leur parcours se terminer.</h3>
            <h3>Suite aux trois soirées de qualifications, les trois projets choisis par le public sont soumis à un second vote auquel seules les personnes ayant assisté à toutes les soirées de qualifications peuvent participer. Le projet ayant obtenu le plus de votes est donc le quatrième projet en prestation lors de la Grande Finale.</h3>
            <h3>La quatrième et dernière soirée du concours présente donc les quatre projets ayant obtenu une place en finale. À l’image du principe de la ronde de qualifications, un projet est couronné grand gagnant de l’édition par le jury sur place et un autre est nommé coup de cœur du public suite à un vote effectué à la fin des quatre prestations. Les différents partenaires sont finalement invités à remettre leur prix au projet de leur choix (voir section “Les prix”). Tous les prix listés peuvent être offerts à l’un ou l’autre des quatre projets en prestations lors de la Grande Finale du concours.</h3>

            <h2>Règlements</h2>
            <div className='cabaret-infos-inner-list'>
                <ul>
                    <li><h3>Tous les artistes et musicien.nes inscrit.es au concours doivent être âgé.es de 18 ans ou plus au 1er janvier 2024.</h3></li>
                    <li><h3>Le contenu présenté au concours doit être 100% original (musique ET textes).</h3></li>
                    <li><h3>L’artiste ou le groupe doit être le-la-les auteur-es des textes et de la musique de toutes les chansons interprétées sur scène.</h3></li>
                    <li><h3>L’artiste ou le groupe ne doit pas avoir signé de contrat d’enregistrement exclusif ou de contrat de licence avec un producteur reconnu par Musicaction ou par la SODEC (PADISQ) en date du 1er janvier 2024.</h3></li>
                    <li><h3>L’artiste ou le groupe ne doit pas avoir d’album professionnel reconnu par Musicaction ou par la SODEC avec le projet qu’il-elle présente.</h3></li>
                    <li><h3>L’artiste ou le groupe ne doit pas faire partie d’une agence ou d’un réseau de diffusion professionnel.</h3></li>
                    <li><h3>Un artiste ou groupe ayant participé à une édition précédente du concours (2 participations maximum) ou ayant déjà posé sa candidature a la possibilité de le faire à nouveau (sauf si son projet a remporté le grand prix public ou jury).</h3></li>
                    <li><h3>L’artiste ou le groupe doit être disponible les samedis 27 janvier, 17 février, 9 mars et 30 mars 2024. Si vous n’êtes pas disponible à une de ces dates, il est très important de nous en aviser. La soirée de qualifications à laquelle l’artiste participera sera déterminée au hasard (sauf finale)</h3></li>
                    <li><h3>L’artiste ou le groupe doit être disponible pour un spectacle entre le 18 et 21 juillet 2024 lors de la 15e édition du festival Le Festif!</h3></li>
                    <li><h3>L’artiste ou la personne-ressource du groupe doit rester disponible pour toute question par rapport au devis technique, aux disponibilités, ou pour toute autre information pertinente.</h3></li>
                    <li><h3>En raison du grand nombre de candidatures envoyées à chaque année, il est à noter qu’un comité de sélection devra choisir les artistes participants au concours. Il est donc obligatoire de nous faire parvenir une démo (+ photo de presse à jour) ou de nous fournir un lien où il est possible d’écouter votre matériel (au minimum 3 chansons et 1 vidéo).</h3></li>
                </ul>
            </div>
        </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosReglements
